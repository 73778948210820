import {
	getClinicCredentials,
	setTokensAndAuthenticate,
} from "@/helpers/utils";
import { setSnackBarOptions } from "@/redux/snackbar";
import { code } from "@/redux/user";
import { Auth, Hub } from "aws-amplify";
import axios from "axios";
import { useDispatch } from "react-redux";

export const useSsoAuth = () => {
	const dispatch = useDispatch();
	const getTokenFromCode = async (code: string) => {
		try {
			const response = await axios.get(
				`${getClinicCredentials("SSO_URL")}get-cognito-tokens/${code}`,
			);
			if (response?.status === 200) {
				let token = JSON.parse(response?.data[0]["tokens"]["S"]);
				return {
					...token,
					sub: response?.data[0]["id"]["S"],
					// rememberMe: response?.data[0]["is_remember_me"]["S"],
					// loggedTime: response?.data[0]["last_login_at"]["S"],
				};
			} else {
				dispatch(
					setSnackBarOptions({
						open: true,
						message: "Something went wrong!",
						type: "error",
					}),
				);
				return response;
			}
		} catch (err) {
			console.error(err);
			dispatch(
				setSnackBarOptions({
					open: true,
					message: "Something went wrong!",
					type: "error",
				}),
			);
		}
	};

	// Verify code from url
	const validateEcoSystemCode = async (codeValue: string) => {
		const decryptAuthToken = await getTokenFromCode(codeValue);
		if (decryptAuthToken) {
			const tokenSetter: boolean =
				setTokensAndAuthenticate(decryptAuthToken);
			// dispatch(
			// 	updateRememberMe({
			// 		rememberMe: decryptAuthToken.rememberMe,
			// 		loggedTime: decryptAuthToken.loggedTime,
			// 	}),
			// );
			if (tokenSetter) {
				dispatch(code(codeValue));
				Auth.currentAuthenticatedUser().then((data) => {
					Hub.dispatch("auth", {
						event: "signIn",
						data,
					});
				});
			}
		}
	};
	return {
		getTokenFromCode,
		validateEcoSystemCode,
	};
};
