import { createSlice, createAsyncThunk, Dispatch } from "@reduxjs/toolkit";
import { API, Auth } from "aws-amplify";
import * as Sentry from "@sentry/nextjs";
import { RootState } from "../store";
import * as worker from "worker-timers";
import {
	setConnectionStatus,
	setEventData,
	setEventName,
	setReloadConfig,
} from "../webSocket";
import { apiName } from "../../helpers/utils";
import { clearAllReduxStates } from "../../helpers";
import { WebSocketEventType } from "../../helpers/types";

const logout = async (dispatch: Dispatch) => {
	await Auth.currentAuthenticatedUser()
		.then(async (currentUser) => {
			await API.post(apiName, `/api/websocket-logout`, {
				body: { user_id: currentUser.attributes.sub },
			}).then(async (data) => {
				await Auth.signOut({ global: true })
					.then(() => {
						// Clearing all the redux states
						clearAllReduxStates();
					})
					.catch(() => {});
			});
		})
		.catch((error) => {
			console.log("Sign Out API error:", error);
		});
};

export const connectToWebSocket = createAsyncThunk(
	"webSocket/connect",
	async (
		{ clinicId, wsUrl }: { clinicId: string; wsUrl: string },
		{ dispatch, getState },
	) => {
		let intervalId: number | null = null;
		const currentUser = await Auth.currentAuthenticatedUser().catch(
			() => {},
		);
		if (currentUser === undefined) return;

		const { webSocket }: { webSocket: IWebSocketState } =
			getState() as RootState;

		if (!webSocket.isConnected) {
			const idToken = (await Auth.currentSession())
				.getIdToken()
				.getJwtToken();
			const connection = new WebSocket(`${wsUrl}?idToken=${idToken}`);
			connection.onopen = () => {
				intervalId = worker.setInterval(() => {
					connection.send("ping");
				}, 300000);

				connection.send(
					JSON.stringify({
						action: "login",
						message: currentUser.attributes.sub,
						clinicId,
						user_type: "CLINIC_PORTAL",
					}),
				);

				dispatch(setConnectionStatus(true));
			};

			connection.onmessage = (event) => {
				if (event.data !== "pong") {
					const parsedEvent = JSON.parse(event.data);
					dispatch(
						handleWebSocketEvent(
							parsedEvent,
							currentUser.attributes.sub,
						),
					);
				}
			};

			connection.onclose = () => {
				if (intervalId) {
					worker.clearInterval(intervalId);
				}
				dispatch(setConnectionStatus(false));
			};

			connection.onerror = (error) => {
				console.error("Websocket failed");
				Sentry.captureException(error);
			};
		}
	},
);

export const handleWebSocketEvent =
	(event: WebSocketEventType, userId: string) =>
	async (dispatch: Dispatch) => {
		try {
			if (event.event === "clinic-portal-event") {
				const eventName = event.data.message.eventName;
				const eventData = event.data.message.data;
				dispatch(setEventName(eventName));
				dispatch(setEventData(eventData));
				switch (eventName) {
					case "on-demand-reload-configurations":
						dispatch(setReloadConfig(true));
						break;
					case "user-role-update":
						if (eventData === userId) {
							await logout(dispatch);
						}
						break;
					default:
						break;
				}
			}
		} catch (error) {
			console.error("webSocketEventHandler failed");
			Sentry.captureException(error);
		}
	};
