import { useEffect, useState } from "react";
import {
	apiName,
	checkPermission,
	defaultRedirectingRouteBasedOnRole,
	getClinicCredentials,
	getClinicDomain,
} from "../../helpers/utils";
import CustomLoader from "../CustomLoader";
import SnackBar from "../SnackBar";
import InActivityWrapper from "./InActivityWrapper";
import { setToken } from "../../redux/zendeskAuth";
import { API, Auth } from "aws-amplify";
import { setIsProfileImageUpdated } from "../../redux/user";
import { connectToWebSocket } from "../../redux/thunk/webSocket";
import { clearAllReduxStates } from "../../helpers";
import { setSnackBarOptions } from "../../redux/snackbar";
import { AppDispatch, RootState } from "../../redux/store";
import { useSelector } from "react-redux";
import { useRouter } from "next/router";
import { NextComponentType, NextPageContext } from "next";
import useIndexedDbSetter from "../../hooks/useIndexedDbSetter";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../hooks/auth";
import dayjs from "dayjs";
import "dayjs/locale/en";
import "dayjs/locale/fr";
import "dayjs/locale/es";
import { logoutRouteHandler } from "@/helpers/auth";
import { useSsoAuth } from "@/hooks/ssoAuth";

function ComponentWrapper({
	Component,
	pageProps,
}: {
	Component: NextComponentType<NextPageContext, any, any>;
	pageProps: any;
}) {
	const { loading: indexedDBLoading } = useIndexedDbSetter();
	const router = useRouter();
	const dispatch = useDispatch<AppDispatch>();
	const { i18n } = useTranslation();
	const { isAuthenticated } = useSelector((state: RootState) => state.auth);

	// const {
	// 	isAuthenticated,
	// 	loading: authLoading,
	// 	validateEcoSystemCode,
	// } = useAuth();
	const { validateEcoSystemCode } = useSsoAuth();
	const { languageLoader } = useSelector(
		(state: RootState) => state.language,
	);
	const { token } = useSelector((state: RootState) => state.zendeskAuth);
	const { id } = useSelector((state: RootState) => state.user);
	const [loading, setLoading] = useState(true);
	const [permissionsLoading, setPermissionsLoading] = useState(false);

	const { role, isProfileImageUpdated } = useSelector(
		(state: RootState) => state.user,
	);
	const { open, message, type } = useSelector(
		(state: RootState) => state.snackbar,
	);

	const onSnackBarClose = () => {
		dispatch(
			setSnackBarOptions({ open: !open, message: message, type: type }),
		);
	};

	// This useEffect runs when user changes their language and sets the dayjs locale to the selected language

	useEffect(() => {
		if (i18n.resolvedLanguage === "en") {
			dayjs.locale("en");
		} else if (i18n.resolvedLanguage === "fr") {
			dayjs.locale("fr");
		} else if (i18n.resolvedLanguage === "es") {
			dayjs.locale("es");
		}
	}, [i18n.resolvedLanguage]);

	const clearReduxStates = async () => {
		//If user is logged out but data is present in redux, we clear all the redux states
		const currentUser = await Auth.currentAuthenticatedUser().catch(
			() => {},
		);
		if (
			currentUser === undefined &&
			router.pathname.includes("/login") &&
			id
		) {
			clearAllReduxStates();
		}
	};

	useEffect(() => {
		clearReduxStates();
	}, [Component]);

	const clinicId = getClinicDomain();
	const wsUrl = getClinicCredentials("WEBSOCKET_URL");
	useEffect(() => {
		// Websocket connection logic
		if (isAuthenticated) {
			dispatch(connectToWebSocket({ clinicId, wsUrl }));
		}
	}, [Component, isAuthenticated]);

	const updateUserProfilePicture = async () => {
		const currentUser = await Auth.currentAuthenticatedUser().catch(
			() => {},
		);
		if (currentUser !== undefined && isProfileImageUpdated === false) {
			await API.get(
				apiName,
				`api/user-management/get-profile-link`,
				{},
			).then((response) => {
				Auth.updateUserAttributes(currentUser, {
					picture: response.data,
				}).then(() => {
					dispatch(setIsProfileImageUpdated(true));
				});
			});
		}
	};

	const getZendeskToken = async () => {
		const currentUser = await Auth.currentAuthenticatedUser().catch(
			() => {},
		);
		if (currentUser !== undefined && token === "") {
			await API.post(apiName, `api/user-management/get-zendesk-token`, {
				body: {
					external_id: currentUser.attributes.sub,
					email: currentUser.attributes.email,
					email_verified: true,
					name:
						currentUser.attributes.given_name +
						" " +
						currentUser.attributes.family_name,
					scope: "user",
				},
			}).then((response) => {
				dispatch(setToken(response));
				// localStorage.setItem("zendeskToken", response);
				// zendeskApi("messenger", "loginUser", function (callback: any) {
				// 	console.log("user logged in");
				// 	callback(response);
				// });
			});
		}
	};

	useEffect(() => {
		updateUserProfilePicture();
	}, [Component]);

	useEffect(() => {
		// initial call to get zendesk token and save it in local storage if the user id exists
		if (id) {
			getZendeskToken();
		}
	}, [id]);

	useEffect(() => {
		if (role) {
			setPermissionsLoading(true);
			const allProtectedRoutes = [
				"dashboard",
				"referrals",
				"leads",
				"patients",
				"public-funded-waitlist",
				"referring-physician",
				"user-management",
				"configurations",
			];
			const currentRoute = router.pathname.split("/")[1] as
				| "dashboard"
				| "referrals"
				| "leads"
				| "patients"
				| "public-funded-waitlist"
				| "referring-physician"
				| "user-management"
				| "configurations";

			//Only checking permission on protectedRoutes
			if (allProtectedRoutes.includes(currentRoute)) {
				if (checkPermission(role, currentRoute, "view") === false) {
					router.replace("/404");
				} else {
					setPermissionsLoading(false);
				}
			} else {
				setPermissionsLoading(false);
			}
		}
	}, [Component]);

	useEffect(() => {
		setLoading(true);
		if (
			// !authLoading&&
			router.isReady
		) {
			if (isAuthenticated) {
				if (router.pathname === "/") {
					if (role) {
						router
							.replace(defaultRedirectingRouteBasedOnRole(role))
							.then(() => {
								setLoading(false);
							});
					}
				} else {
					setLoading(false);
				}
			} else {
				if (
					!router.pathname.includes("/user-invitation") &&
					!router.pathname.includes("/404") &&
					!router.pathname.includes("/forgot-password") &&
					!router.pathname.includes("/NewPassword") &&
					!router.pathname.includes("/PasswordExpired") &&
					!router.pathname.includes("/privacy-policy") &&
					!router.pathname.includes("/viewIcons")
				) {
					if (router.isReady) {
						let codeValue: any = router.query.code;
						if (codeValue) {
							(async () => {
								await validateEcoSystemCode(codeValue);
								setLoading(false);
							})();
						} else {
							logoutRouteHandler(router, dispatch);
							setLoading(false);
						}
					} else {
						logoutRouteHandler(router, dispatch);
						setLoading(false);
						// router.replace("/login").then(() => {
						// 	setLoading(false);
						// });
					}
					setLoading(false);
				} else {
					setLoading(false);
				}
			}
		} else {
			setLoading(false);
		}
	}, [
		// authLoading,
		isAuthenticated,
		Component,
		router.isReady,
	]);

	// if (
	// 	loading ||
	// 	languageLoader ||
	// 	// authLoading ||
	// 	permissionsLoading ||
	// 	!indexedDBLoading
	// ) {
	// 	return <CustomLoader open={true} />;
	// }

	return (
		<>
			{loading || languageLoader || permissionsLoading ? (
				<CustomLoader open={true} />
			) : null}
			<SnackBar
				open={open}
				onClose={onSnackBarClose}
				message={message}
				type={type}
			/>
			{/*  */}
			<InActivityWrapper />
			<Component {...pageProps} />
		</>
	);
}

export default ComponentWrapper;
