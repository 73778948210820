import React from "react";
import { alpha, Backdrop, CircularProgress, useTheme } from "@mui/material";

interface LoaderProps {
	open: boolean;
	color?: string;
	bgOpacity?: number;
}

function CustomLoader({ open, color, bgOpacity }: LoaderProps) {
	// Theme selector
	const theme = useTheme();
	return (
		<Backdrop
			sx={{
				borderRadius: 2,
				backgroundColor: alpha(
					theme.palette.white.main,
					bgOpacity ?? 0.6,
				),
				zIndex: 9999,
			}}
			open={open}
		>
			<CircularProgress
				sx={{
					color: color || theme.palette.primary.main,
				}}
			/>
		</Backdrop>
	);
}

export default React.memo(CustomLoader);
