import { combineReducers, configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { persistReducer, persistStore } from "redux-persist";
import authReducer from "./auth";
import userReducer from "./user";
import menuReducer from "./menus";
import permissionReducer from "./permissions";
import leadsFiltersReducer from "./leadsFilters";
import userManagementFiltersReducer from "./userManagementFilters";
import leadsTableStateReducer from "./LeadsTableState";
import referralsTableStateReducer from "./referralsTableState";
import faxReferralsTableStateReducer from "./faxReferralsTableState";
import patientsTableStateReducer from "./patientsTableState";
import publicWaitlistTableStateReducer from "./publicWaitlistTableState";
import referringPhysiciansTableStateReducer from "./referringPhysiciansTableState";
import userManagementTableStateReducer from "./userManagementTableStates";
import addPartnerDataReducer from "./addPartner";
import addPartnerPatientsDataReducer from "./addPartnerPatients";
import languageReducers from "./language";
import patientsFiltersReducer from "./patientsFilter";
import snackBarReducer from "./snackbar";
import timerReducer from "./timer";
import zendeskAuthReducer from "./zendeskAuth";
import webSocketReducer from "./webSocket";
import productReducer from "./products";
import miscSlice from "./misc";

const persistConfig = {
	key: "root",
	storage,
	blacklist: ["timer", "webSocket", "misc"],
};

const rootReducer = combineReducers({
	auth: authReducer,
	user: userReducer,
	menus: menuReducer,
	permissions: permissionReducer,
	leadsFilters: leadsFiltersReducer,
	userManagementFilters: userManagementFiltersReducer,
	leadsTableState: leadsTableStateReducer,
	referralsTableState: referralsTableStateReducer,
	faxReferralsTableState: faxReferralsTableStateReducer,
	patientsFilter: patientsFiltersReducer,
	patientsTableState: patientsTableStateReducer,
	publicWaitlistTableState: publicWaitlistTableStateReducer,
	referringPhysiciansTableState: referringPhysiciansTableStateReducer,
	userManagementTableState: userManagementTableStateReducer,
	addPartnerData: addPartnerDataReducer,
	addPartnerPatientsData: addPartnerPatientsDataReducer,
	language: languageReducers,
	snackbar: snackBarReducer,
	timer: timerReducer,
	zendeskAuth: zendeskAuthReducer,
	webSocket: webSocketReducer,
	products: productReducer,
	misc: miscSlice,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
	reducer: persistedReducer,
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware({ serializableCheck: false }),
});

export const persistor = persistStore(store);
// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
