import { createSlice } from "@reduxjs/toolkit";

export const authSlice = createSlice({
	name: "auth",
	initialState: {
		isAuthenticated: false,
		isAuthenticating: false,
	},
	reducers: {
		setAuthentication: (state, action) => {
			state.isAuthenticated = action.payload;
		},
		setAuthLoading(state, action) {
			state.isAuthenticating = action.payload;
		},
	},
});

export const { setAuthentication, setAuthLoading } = authSlice.actions;

export default authSlice.reducer;
