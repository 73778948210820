import { ProductListProps } from "../helpers/types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: Array<ProductListProps> = [];

export const userSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    loadProducts: (state, action: PayloadAction<any>) => {
      return action.payload;
    },
    resetProducts: () => {
      return initialState;
    },
  },
});

export const { loadProducts, resetProducts } = userSlice.actions;

export default userSlice.reducer;
