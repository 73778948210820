import { MainDB } from "@/helpers/db.model";
import { GlobalConstantValuesInterface } from "@/helpers/dbTypes";

export const updateDatabase = async (
	response: {
		data: GlobalConstantValuesInterface;
		message: string;
		success: boolean;
	},
	setData: Function,
	mainDb: MainDB,
) => {
	if (response.data) {
		// Collect all setData promises into an array
		const promises = [
			setData(mainDb.archivedReasons, response.data.archived_reasons),
			setData(mainDb.doctorList, response.data.doctor_list),
			setData(mainDb.globalConfig, response.data.global_config),
			setData(mainDb.careCoordinator, response.data.care_coordinator),
			setData(mainDb.priorityList, response.data.priority_list),
			setData(mainDb.clinicLanguages, response.data.clinic_languages),
			setData(mainDb.contactResolution, response.data.contact_resolution),
			setData(
				mainDb.virtualCareProvider,
				response.data.virtual_care_provider,
			),
			setData(
				mainDb.appointmentCancelledReasons,
				response.data.appointment_cancelled_reasons,
			),
			setData(mainDb.roles, response.data.roles),
			setData(
				mainDb.referringPhysicianOptoutDropdown,
				response.data.referring_physician_optout_dropdown,
			),
			setData(
				mainDb.specialtyDropdown,
				response.data.speciality_dropdown,
			),
			setData(mainDb.locations, response.data.locations),
			setData(
				mainDb.initialContactChannel,
				response.data.initial_contact_channel,
			),
			setData(mainDb.benefitProvider, response.data.benefit_provider),
			setData(
				mainDb.patientPreferences,
				response.data.patient_preferences,
			),
			setData(
				mainDb.reasonForReferrals,
				response.data.reason_for_referrals,
			),
			setData(mainDb.patientLanguages, response.data.patient_languages),
			setData(
				mainDb.referringPhysicians,
				response.data.referring_physicians,
			),
			setData(mainDb.country, response.data.country),
			setData(mainDb.pronouns, response.data.pronouns),
			setData(mainDb.appointmentTypes, response.data.appointment_types),
			response.data.fax_archived_reasons &&
				setData(
					mainDb.faxReferralArchiveReasons,
					response.data.fax_archived_reasons || [],
				),
			response.data.visiting_purpose &&
				setData(
					mainDb.visitingPurpose,
					response.data.visiting_purpose || [],
				),
			setData(mainDb.countryProvinces, response.data.country_provinces),
			setData(
				mainDb.preferredMethodOfContact,
				response.data.preferred_method_of_contact,
			),
			setData(
				mainDb.healthCardProvinceInformation,
				response.data.health_card_informations,
			),
		].filter(Boolean); // Remove any null or undefined entries from the array

		// Wait for all promises to complete
		await Promise.all(promises);
	}
};
