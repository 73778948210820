// patient listing ws events

export const wsPatientListingEvents = [
	"lead-move-to-patients",
	"patient-account-closure",
	"patient-unarchive",
	"new-notification",
	"mark-as-read-notification",
];

// lead listing ws events
export const wsLeadListingEvents = new Set([
	"patient-account-closure",
	"lead-list-update-event",
	"referral-move-to-lead-inbox",
	"moved-to-lead-form-submitted",
	"lead-profile-status-update",
	"patient-information-update",
	"contact_attempts_event", // for all tabs except archive
	"lead-invitation-sent",
	"patient-unarchive",
	"new-notification",
	"mark-as-read-notification",
]);

export const wsReferalListingEvents = new Set([
	"patient-account-closure",
	"referral-list-update-event",
	"referral-move-to-lead-inbox",
	"patient-unarchive",
	"new-notification",
	"mark-as-read-notification",
]);
