import { SortingState } from "@tanstack/react-table";
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import { AsYouType, CountryCode } from "libphonenumber-js/max";
import { evaluate } from "mathjs";
import { SetStateAction, SyntheticEvent } from "react";
import { CheckIfEmailOrPhoneIsTakenProps } from "../hooks/conf";
import { ecoSystemAuthTokens, ProductListProps, ResponseType } from "./types";
import { CountryProvincesInterface } from "./dbTypes";
import { languageLabels } from "./misc";
import AppointmentInterface from "../interfaces/AppointmentInterface";
import PatientDetailInterface from "../interfaces/PatientDetailInterface";
import LeadDetailsInterface from "../interfaces/leadDetailsInterface";
dayjs.extend(utc);

type LanguageMapping = {
	[key: string]: string;
};
const special: LanguageMapping[] = [
	{ en: "zeroth", fr: "zéroième" },
	{ en: "first", fr: "premier" },
	{ en: "second", fr: "deuxième" },
	{ en: "third", fr: "troisième" },
	{ en: "fourth", fr: "quatrième" },
	{ en: "fifth", fr: "cinquième" },
	{ en: "sixth", fr: "sixième" },
	{ en: "seventh", fr: "septième" },
	{ en: "eighth", fr: "huitième" },
	{ en: "ninth", fr: "neuvième" },
	{ en: "tenth", fr: "dixième" },
	{ en: "eleventh", fr: "onzième" },
	{ en: "twelfth", fr: "douzième" },
	{ en: "thirteenth", fr: "treizième" },
	{ en: "fourteenth", fr: "quatorzième" },
	{ en: "fifteenth", fr: "quinzième" },
	{ en: "sixteenth", fr: "seizième" },
	{ en: "seventeenth", fr: "dix-septième" },
	{ en: "eighteenth", fr: "dix-huitième" },
	{ en: "nineteenth", fr: "dix-neuvième" },
];
const deca: LanguageMapping[] = [
	{ en: "twent", fr: "vingt" },
	{ en: "thirt", fr: "trente" },
	{ en: "fort", fr: "quarante" },
	{ en: "fift", fr: "cinquante" },
	{ en: "sixt", fr: "soixante" },
	{ en: "sevent", fr: "soixante-dix" },
	{ en: "eight", fr: "quatre-vingt" },
	{ en: "ninet", fr: "quatre-vingt-dix" },
];

const MAX_VALID_YR = 9999;
const MIN_VALID_YR = 1900;

export const preferredMethodOfContact = [
	{
		label: "None",
		value: "",
	},
	{
		label: "Phone",
		value: "phone",
	},
	{
		label: "Email",
		value: "email",
	},
	{
		label: "Text",
		value: "text",
	},
];

export const apiName = "Connect";

const doctorRestrictionFeatures = ["leads-appointments.create"];

export const permissions: Array<{
	role: string;
	routes: Array<{
		route: string;
		features: Array<string>;
		restricted?: Array<string>;
	}>;
}> = [
	{
		role: "CNP_Administrator",
		routes: [
			{
				route: "dashboard",
				features: ["view"],
			},
			{
				route: "referrals",
				features: ["view"],
			},
			{
				route: "leads",
				features: ["view"],
			},
			{
				route: "patients",
				features: ["view"],
			},
			{
				route: "referring-physician",
				features: ["view"],
			},
			{
				route: "user-management",
				features: ["view"],
			},
			{
				route: "configurations",
				features: ["view"],
			},
		],
	},
	{
		role: "CNP_CareCoordinator",
		routes: [
			{
				route: "dashboard",
				features: ["view"],
			},
			{
				route: "referrals",
				features: ["view"],
			},
			{
				route: "leads",
				features: ["view"],
			},
			{
				route: "patients",
				features: ["view"],
			},
			{
				route: "referring-physician",
				features: ["view"],
			},
		],
	},
	{
		role: "CNP_MOA",
		routes: [
			{
				route: "leads",
				features: ["view"],
			},
			{
				route: "patients",
				features: ["view"],
			},
		],
	},
	{
		role: "CNP_PatientsNavigator",
		routes: [
			{
				route: "patients",
				features: ["view"],
			},
		],
	},
	{
		role: "CNP_Nurse",
		routes: [
			{
				route: "dashboard",
				features: ["view"],
			},
			{
				route: "referrals",
				features: ["view"],
			},
			{
				route: "leads",
				features: ["view"],
			},
			{
				route: "patients",
				features: ["view"],
			},
			{
				route: "referring-physician",
				features: ["view"],
			},
		],
	},
	{
		role: "CNP_Doctor",
		routes: [
			{
				route: "leads",
				features: ["view"],
				restricted: doctorRestrictionFeatures,
			},
			{
				route: "patients",
				features: ["view"],
			},
		],
	},
];

export const subLocalities = [
	"sublocality",
	"sublocality_level_1",
	"sublocality_level_2",
	"sublocality_level_3",
	"sublocality_level_4",
	"sublocality_level_5",
];

// Returning dimensions of logos
export const getLogoDimensions = (
	logoType: "LOGO" | "SMALL_LOGO",
	dimensionType: "WIDTH" | "HEIGHT",
) => {
	const environment = window.location.href;
	if (environment.includes("localhost") || environment.includes("staging")) {
		const clinicName = getClinicDomain();
		// Getting all the clinic credentials from the env variables which are stored as a stringified json
		const allClinicsEnvs = process.env.ALL_CLINIC_ENVS;
		// Doing this to get rid of typescript error
		if (allClinicsEnvs !== undefined) {
			const allClinicCredentials = JSON.parse(allClinicsEnvs);
			// Getting the clinic credentials using the clinic name
			const clinicCredentials = allClinicCredentials[clinicName];
			//Returning the width or height of the logo that was asked
			return clinicCredentials[`${logoType}_${dimensionType}`];
		}
		//If the clinic credentials are not present, returning an empty string
		return "";
	} else {
		//Getting clinic name
		const clinicName = environment.split("/")[2].split(".")[0];
		// Getting all the clinic credentials from the env variables which are stored as a stringified json
		const allClinicsEnvs = process.env.ALL_CLINIC_ENVS;
		// Doing this to get rid of typescript error
		if (allClinicsEnvs !== undefined) {
			const allClinicCredentials = JSON.parse(allClinicsEnvs);
			let clinicCredentials;
			//checking if the url has preproduction in it
			if (environment.includes("preproduction")) {
				const preproductionClinicCredentials =
					allClinicCredentials["preproduction"];
				//fetch the clinic credentials from the preproductionClinicCredentials object
				clinicCredentials = preproductionClinicCredentials[clinicName];
				// return clinicCredentials[keyName];
			} else {
				//fetch the clinic credentials from the productionClinicCredentials object
				const productionClinicCredentials =
					allClinicCredentials["production"];
				clinicCredentials = productionClinicCredentials[clinicName];
				// return clinicCredentials[keyName];
			}
			return clinicCredentials[`${logoType}_${dimensionType}`];
		}
		//If the clinic credentials are not present, returning an empty string
		return "";
	}
};

//Returns a string with "CLINIC_NAME" replaced with the current clinic name
export const replaceClinicName = (string: string, capitalize?: boolean) => {
	if (capitalize) {
		return string.replaceAll(
			"CLINIC_NAME",
			getClinicCredentials("CLINIC_NAME").toUpperCase(),
		);
	} else {
		return string.replaceAll(
			"CLINIC_NAME",
			getClinicCredentials("CLINIC_NAME"),
		);
	}
};

//Returns a string with "CLINIC_ADDRESS" replaced with the current clinic addresses
export const replaceClinicAddress = (string: string) => {
	return string.replaceAll("CLINIC_ADDRESS", getClinicAddresses());
};

//Returns a string with "CLINIC_DOMAIN" replaced with the current clinic domain
export const replaceClinicDomain = (string: string) => {
	return string.replaceAll("CLINIC_DOMAIN", getClinicDomain());
};

//Returns a string with "CLINIC_WEBSITE" replaced with the current clinic website
export const replaceClinicWebsite = (string: string) => {
	return string.replaceAll(
		"CLINIC_WEBSITE",
		getClinicCredentials("CLINIC_WEBSITE"),
	);
};

//Returns a string with "CLINIC_EMAIL" replaced with the current clinic website
export const replaceClinicEmail = (string: string) => {
	return string.replace("CLINIC_EMAIL", getClinicCredentials("CLINIC_EMAIL"));
};

//Returns a string of all the clinic addresses
export const getClinicAddresses = () => {
	const clinicAddresses = getClinicCredentials("CLINIC_ADDRESSES");
	if (clinicAddresses.length) {
		if (clinicAddresses.length === 1) {
			return clinicAddresses[0];
		} else {
			const formatter = new Intl.ListFormat("en", {
				style: "long",
				type: "conjunction",
			});
			return formatter.format(clinicAddresses);
		}
	}
};

//Gets clinic name based on environment and subdomain
export const getClinicDomain = () => {
	if (window.location.href.split("/")[2].includes("localhost")) {
		return "localhost";
	} else {
		return window.location.href.split("/")[2].split(".")[0];
	}
};

//Gets clinic keys using subdomain (clinic name)
export const getClinicCredentials = (
	keyName:
		| "SSO_URL"
		| "PFP_CLOUDFRONT_URL"
		| "WEBSOCKET_URL"
		| "ZENDESK_SECRET_KEY"
		| "BACKEND_URL"
		| "AWS_REGION"
		| "AWS_USERPOOL_ID"
		| "AWS_IDENTITYPOOL_ID"
		| "AWS_USERPOOL_APP_ID"
		| "AWS_AUTHFLOW_TYPE"
		| "AWS_S3_BUCKET"
		| "AWS_PINPOINT_APP_ID"
		| "GOOGLE_TAG_MANAGER_ID"
		| "CLINIC_NAME"
		| "CLINIC_WEBSITE"
		| "CLINIC_EMAIL"
		| "CLINIC_CONTACT_US"
		| "CLINIC_PROVINCE"
		| "CLINIC_COUNTRY"
		| "CLINIC_ADDRESSES"
		| "STATIC_CONTENT_BUCKET"
		| "GOOGLE_API_KEY"
		| "LANGUAGES",
) => {
	const environment = window.location.href;
	if (environment.includes("localhost") || environment.includes("staging")) {
		const clinicName = getClinicDomain();
		// Getting all the clinic credentials from the env variables which are stored as a stringified json
		const allClinicsEnvs = process.env.ALL_CLINIC_ENVS;
		// Doing this to get rid of typescript error
		if (allClinicsEnvs !== undefined) {
			const allClinicCredentials = JSON.parse(allClinicsEnvs);
			// Getting the clinic credentials using the clinic name
			const clinicCredentials = allClinicCredentials[clinicName];
			//Returning the value of the key that was asked
			return clinicCredentials[keyName];
		}
		//If the clinic credentials are not present, returning an empty string
		return "";
	} else {
		//Getting clinic name
		const clinicName = environment.split("/")[2].split(".")[0];
		// Getting all the clinic credentials from the env variables which are stored as a stringified json
		const allClinicsEnvs = process.env.ALL_CLINIC_ENVS;
		// Doing this to get rid of typescript error
		if (allClinicsEnvs !== undefined) {
			const allClinicCredentials = JSON.parse(allClinicsEnvs);
			let clinicCredentials;
			//checking if the url has preproduction in it
			if (environment.includes("preproduction")) {
				const preproductionClinicCredentials =
					allClinicCredentials["preproduction"];
				//fetch the clinic credentials from the preproductionClinicCredentials object
				clinicCredentials = preproductionClinicCredentials[clinicName];
				// return clinicCredentials[keyName];
			} else {
				//fetch the clinic credentials from the productionClinicCredentials object
				const productionClinicCredentials =
					allClinicCredentials["production"];
				clinicCredentials = productionClinicCredentials[clinicName];
				// return clinicCredentials[keyName];
			}
			return clinicCredentials[keyName];
		}
		//If the clinic credentials are not present, returning an empty string
		return "";
	}
};

/**
 *
 * @param email
 * @returns Boolean if email is valid for clinic portal
 */

export const checkEmailDomain = (email: string, forPatient?: boolean) => {
	const allClinicsEnvs = process.env.ALL_CLINIC_ENVS;
	let allowedDomains = new Set<string>([]);
	if (!forPatient) {
		allowedDomains = new Set<string>([
			"thefertilitypartners.com",
			"yopmail.com",
			"mailinator.com",
			"testinbox.dev",
		]);
	}

	if (allClinicsEnvs !== undefined) {
		const allClinicCredentials = JSON.parse(allClinicsEnvs);
		const clinicsValuesArr: Array<{ CLINIC_EMAIL?: string }> =
			Object.values(allClinicCredentials);
		clinicsValuesArr.forEach((item) => {
			if (item.CLINIC_EMAIL) {
				allowedDomains.add(
					item.CLINIC_EMAIL.toString().split("@").pop()!,
				);
			}
		});

		const environment =
			process.env.NEXT_PUBLIC_DEPLOYMENT_ENVIRONMENT ?? null;
		if (environment) {
			const clinicCredentials = allClinicCredentials[environment];
			const clinicsValuesArr: Array<{ CLINIC_EMAIL?: string }> =
				Object.values(clinicCredentials);
			clinicsValuesArr.forEach((item) => {
				if (item.CLINIC_EMAIL) {
					allowedDomains.add(
						item.CLINIC_EMAIL.toString().split("@").pop()!,
					);
				}
			});
		}
	}
	let domain = email.split("@").pop();

	// Check if domain is not allowed
	if (domain !== undefined && allowedDomains.has(domain)) {
		return true;
	} else {
		return false;
	}
};

/**
 * Capitalizes the first letter of each word in a string.
 * If the input is undefined or null, it returns an empty string.
 *
 * @param text - A string whose first letter needs to be capitalized.
 * @returns A string where each word's first letter is capitalized.
 */
export const capitalizeFirstCharacter = (text: string) => {
	if (typeof text !== "string") {
		return ""; // Return an empty string if input is not a string
	}

	return text
		.split(" ")
		.map((word) => word.charAt(0).toUpperCase() + word.slice(1))
		.join(" ");
};

/**
 *
 * Use this function when you have date ONLY
 * @param date - A date string in format like "YYYY-MM-DD HH:MM:SS"
 * @returns A date string in format like "MM/DD/YYYY"
 */
export const formatDate = (date: string) => {
	const timedDate = date.split(" ")[0] + "T00:00:00";
	const utcDateToLocalTime = new Date(timedDate).toString();
	const newDate = dayjs(utcDateToLocalTime).format("MM/DD/YYYY");
	return newDate;
};

export const formatDateAsLongText = (date: string) => {
	const timedDate = date.split(" ")[0] + "T00:00:00";
	const utcDateToLocalTime = new Date(timedDate).toString();
	const newDate = dayjs(utcDateToLocalTime).format("MMMM D, YYYY");
	return newDate;
};

/**
 *
 * Use this function when you have date ONLY
 * @param date - A date string in format like "YYYY-MM-DD HH:MM:SS"
 * @returns A date string in format like "MMM. DD, YYYY"
 */
export const formatDateAsShortText = (date: string) => {
	const timedDate = date.split(" ")[0] + "T00:00:00";
	const utcDateToLocalTime = new Date(timedDate).toString();
	const newDate = dayjs(utcDateToLocalTime).format("MMM. DD, YYYY");
	return newDate;
};

/**
 *
 * @param dateTime - A date string in format like "YYYY-MM-DD HH:MM:SS"
 * @param isUTC - Should date be converted from UTC
 * @param format - Format to style the date string. Use dayJS formatting structure
 * @returns A date string in format like "MMM. DD, YYYY hh:mm A" or your entered format
 */
export const formatDateTime = (
	dateTime: string,
	isUTC: boolean,
	format?: string,
) => {
	//Converting time to local time using default date function because dayjs is buggy for UTC conversion

	let dateFormat = "MMM. DD, YYYY hh:mm A";

	if (format) {
		const utcDateToLocalTime = dayjs(
			dayjs.utc(dateTime).toDate().toString(),
		);
		const newDate = dayjs(isUTC ? utcDateToLocalTime : dateTime).format(
			format,
		);
		return newDate;
	} else {
		const utcDateToLocalTime = dayjs(
			dayjs.utc(dateTime).toDate().toString(),
		);
		const newDate = dayjs(isUTC ? utcDateToLocalTime : dateTime).format(
			dateFormat,
		);
		return newDate;
	}
};

/**
 *
 * @param dateTime - A date string in format like "YYYY-MM-DD HH:MM:SS"
 * @returns A date string converted from UTC
 */
export const convertDateFromUTC = (dateTime: string) => {
	const utcDateToLocalTime = dayjs(dayjs.utc(dateTime).toDate().toString());
	return utcDateToLocalTime;
};

/**
 *
 * @param number - A number from 0 to 99
 * @returns A string of the number e.g. "Zeroth", "First" ... "Ninety-Ninth"
 */
export const stringifyNumber = (number: number, lang?: string): string => {
	const effectiveLang = lang ?? "en";
	if (number < 20) return special[number][effectiveLang];
	if (number % 10 === 0) return deca[Math.floor(number / 10) - 2] + "ieth";
	return deca[Math.floor(number / 10) - 2] + "y-" + special[number % 10];
};

interface ConvertToCmProps {
	convertTo: "cm";
	feet: number;
	inches: number;
}

interface ConvertToFtInProps {
	convertTo: "ft/in";
	centimeter: number;
}

type ConvertHeightProps = ConvertToCmProps | ConvertToFtInProps;

/**
 *
 * @param props - If convertTo is "cm" then feet and inches are required. If convertTo is "ft/in" then centimeter is required
 * @returns Value in centimeters if convertTo is "cm" and value in feet and inches if convertTo is "ft/in"
 */
export const convertHeight = (
	props: ConvertHeightProps,
):
	| number
	| {
			feet: number;
			inches: number;
	  } => {
	if (props.convertTo === "ft/in") {
		const heightInInches = props.centimeter / 2.54;
		const centimeterToFeetValue = Math.floor(heightInInches / 12);
		const centimeterToInchesValue = Math.round(
			heightInInches - 12 * centimeterToFeetValue,
		);

		return {
			feet: centimeterToFeetValue,
			inches: centimeterToInchesValue,
		};
	} else if (props.convertTo === "cm") {
		const heightInCentimeters = props.feet * 30.48 + props.inches * 2.54;
		return Number(Math.round(heightInCentimeters));
	}
	return 0;
};

/**
 *
 * @param value - Value to be converted
 * @param convertTo - "conversion_one" or "conversion_two"
 * @param conversion_one_formula - Formula to convert value to conversion_one
 * @param conversion_two_formula - Formula to convert value to conversion_two
 * @param returnType - "number" or "float"
 * @returns Converted value
 */
export const handleConversionCalculation = (
	value: number,
	convertTo: "conversion_one" | "conversion_two",
	conversion_one_formula: string,
	conversion_two_formula: string,
	returnType: "number" | "float",
): number => {
	let result = 0;

	if (conversion_one_formula && conversion_two_formula) {
		if (convertTo === "conversion_one") {
			result = evaluate(conversion_one_formula, {
				conversion_two: value,
			});
		} else if (convertTo === "conversion_two") {
			result = evaluate(conversion_two_formula, {
				conversion_one: value,
			});
		}
	}

	if (returnType === "number") {
		return Math.round(result);
	} else if (returnType === "float") {
		return Number(result.toFixed(2));
	}
	return 0;
};

/**
 *
 * @param dateOfBirth - Date of birth as a datetime value in a string
 * @returns Age
 */
export const ageFromDateOfBirth = (dateOfBirth: string) => {
	const birthDate = new Date(dateOfBirth);
	const difference = Date.now() - birthDate.getTime();
	const age = new Date(difference);

	return Math.abs(age.getUTCFullYear() - 1970);
};

/**
 *
 * @param name - Name
 * @returns Initials of given name
 */
export const getNameInitials = (name: string) => {
	const initials = name
		.split(" ")
		.map((item: string) => item.charAt(0))
		.slice(0, 2)
		.join("")
		.toUpperCase();
	return initials;
};

/**
 *
 * @param allowedExtensions - Array of extensions that are allowed i.e. ["pdf", "jpeg", "png"]
 * @param selectedFiles - Array of names of selected files. Should include extension after name i.e testfile.txt
 * @returns Array of names of files that are allowed
 */
export const findAllowedFiles = (
	allowedExtensions: Array<string>,
	selectedFiles: Array<string>,
) => {
	const selectedFilesDetails: Array<{ name: string; extension: string }> =
		selectedFiles.map((filename: string) => {
			const fileExtension = filename.split(".").at(-1);
			return {
				name: filename,
				extension: fileExtension ? fileExtension.toLowerCase() : "",
			};
		});

	const allowedFiles = selectedFilesDetails.filter(
		(item: { name: string; extension: string }) =>
			allowedExtensions.includes(item.extension),
	);

	const allowedFilesNames = allowedFiles.map(
		(item: { name: string; extension: string }) => item.name,
	);

	return allowedFilesNames;
};

/**
 *
 * @param currentFiles Files currently selected in the onChange function
 * @param allFiles Fall Files selected by the user, including the ones that were already added
 * @param setOpenSnackBar setState function to open snackbar
 * @param setSnackBarMessage setState function to set snackbar message
 * @param setSnackBarType setState function to set snackbar type
 * @returns - Names of all the files that are allowed
 */

export const errorHandlingOnFileSelection = (
	currentFiles: FileList | null,
	setOpenSnackBar: React.Dispatch<React.SetStateAction<boolean>>,
	setSnackBarMessage: React.Dispatch<React.SetStateAction<string>>,
	setSnackBarType: React.Dispatch<
		React.SetStateAction<"success" | "error" | "warning" | "info">
	>,
	allFiles?: Array<File>,
) => {
	//Checking if any of the files have an extention that is not allowed. If yes then throwing an error
	//Getting selectedFileNames
	let selectedFilesNames: Array<string> = [];
	let allFilesSize: number = 0;

	if (currentFiles) {
		for (let i = 0; i < currentFiles.length; i++) {
			const file: File | null = currentFiles.item(i);
			if (file) {
				selectedFilesNames = [...selectedFilesNames, file.name];
				allFilesSize = allFilesSize + file.size;
			}
		}
	}

	if (allFiles) {
		for (let i = 0; i < allFiles.length; i++) {
			const file: File = allFiles[i];
			if (file) {
				allFilesSize = allFilesSize + file.size;
			}
		}
	}

	let allowedFiles = findAllowedFiles(
		["pdf", "jpeg", "png", "jpg", "doc", "docx"],
		selectedFilesNames,
	);

	//If size of all files is greater than 20 mb then dont upload files and show error
	if (bytesToMegaBytes(allFilesSize) > 20) {
		setOpenSnackBar(true);
		setSnackBarMessage("Size of all files should be less than 20MB.");
		setSnackBarType("error");
		allowedFiles = [];
	}
	//If allowedFiles length is less than selectedFilesNames meaning some of the files were not allowed, then we display an error message
	else if (allowedFiles.length < selectedFilesNames.length) {
		setOpenSnackBar(true);
		setSnackBarMessage(
			"Only files of type 'jpeg', 'jpg', 'png', 'pdf', 'doc' and 'docx are allowed.",
		);
		setSnackBarType("error");
	}

	return allowedFiles;
};

/**
 *
 * @param array - Array of dropdown options
 * @param selected - Ids of selected options
 * @param propertyToJoin - String of property that needs to be joined (property that is shown to user e.g. "question" or "option")
 * @returns String with joined names
 */
export const getJoinedNameForDropdown = (
	array: any[],
	selected: number[],
	propertyToJoin: string,
) => {
	let resultq: any = [];

	array.forEach((item) => {
		if (selected.includes(item.id)) {
			resultq.push(capitalizeFirstCharacter(item[propertyToJoin]));
		}
	});
	return resultq.join(", ");
};

export const getJoinedNameFromAssocArray = (
	array: any[],
	propertyToJoin: string,
) => {
	let resultq: any = [];

	array.forEach((item) => {
		resultq.push(capitalizeFirstCharacter(item[propertyToJoin]));
	});
	return resultq.join(", ");
};

/**
 *
 * @param name - Name of uploaded file (must be like )
 * @returns Name with removed timestamp
 */
export const formatFileName = (name: string) => {
	// console.log(name);
	const stringToRemove = name.split("-")[0];
	let newName = name.replace(stringToRemove, "");
	newName = newName.slice(1);
	return newName;
};

function findFirstUniqueChar(longStr: string, shortStr: string): string | null {
	// Define charCount with an index signature to specify that it will have string keys with numeric values
	const charCount: { [key: string]: number } = {};

	// Populate charCount with character frequencies from shortStr
	for (const char of shortStr) {
		charCount[char] = (charCount[char] || 0) + 1;
	}

	// Iterate through longStr to find the first character not present in shortStr
	for (const char of longStr) {
		if (charCount[char]) {
			charCount[char]--; // Decrement the count for this character
		} else {
			return char;
		}
	}

	return null; // If all characters in the longer string are found in the shorter string
}

function findDifferenceChar(str1: string, str2: string) {
	if (str1.length > str2.length) {
		return findFirstUniqueChar(str1, str2);
	} else {
		return findFirstUniqueChar(str2, str1);
	}
}

/**
 *
 * @param value - Phone number in string
 * @param countryCode - Country who's format needs to be applied
 * @returns Masked phone number
 */
export const formatPhoneNumber = (
	value: string,
	previousValue?: string,
	isFormatted?: boolean,
	countryCode: CountryCode = "US", // Default to 'US' if undefined
): string => {
	if (!value) return "";
	let rawValue;
	let diff;
	// Ensure that `findDifferenceChar` handles or is okay with receiving an empty string
	if (previousValue) {
		rawValue = findDifferenceChar(value, previousValue); // Default to empty string if null
		if (rawValue) {
			diff = /[\s()-]/.test(rawValue);
		}
	}

	if (isFormatted === undefined) {
		value = value.toString();

		if (value.includes("(") && !value.includes(")")) {
			return value.replace("(", "");
		}
		return new AsYouType(countryCode).input(value);
	}

	if (value.charAt(0) === "+") {
		console.log("value.length ", value.length);
		if (value.length >= 15) {
			return new AsYouType(countryCode).input(value);
		} else {
			return value;
		}
	}

	if (value.length >= 10 && isFormatted === false) {
		return new AsYouType(countryCode).input(value);
	} else if (value.length > 16 && isFormatted === true) {
		return new AsYouType(countryCode).input(value);
	} else if (!diff && value.length >= 13) {
		return new AsYouType(countryCode).input(value);
	} else {
		return value;
	}
};

/**
 *
 * @param value - Phone number in string
 * @returns Phone number without calling code
 */
export const parsePhoneNumberWithoutCallingCode = (value: string) => {
	let phoneNumber = value;
	if (phoneNumber[0] === "+" || phoneNumber[0] === "1") {
		phoneNumber = phoneNumber.substring(1);
		if (phoneNumber[0] === "+" || phoneNumber[0] === "1") {
			phoneNumber = phoneNumber.substring(1);
		}
	}
	return phoneNumber;
};

export const isSelectedDateTimeBeforeCurrentDateTime = (
	selectedDate: Dayjs,
	selectedTime: Dayjs,
) => {
	const date = dayjs(selectedDate).get("date");
	const month = dayjs(selectedDate).get("month");
	const year = dayjs(selectedDate).get("year");

	const hour = dayjs(selectedTime).get("hour");
	const minute = dayjs(selectedTime).get("minute");
	const second = dayjs(selectedTime).get("second");

	const currentDateTime = dayjs().utc();
	const userSelectedDateTime = dayjs()
		.set("date", date)
		.set("month", month)
		.set("year", year)
		.set("hour", hour)
		.set("minute", minute)
		.set("second", second)
		.utc();

	return userSelectedDateTime.isBefore(currentDateTime);
};

export const isToDateTimeBeforeOrSameAsFromDateTime = (
	selectedDate: Dayjs,
	selectedFromTime: Dayjs,
	selectedToTime: Dayjs,
) => {
	const date = dayjs(selectedDate).get("date");
	const month = dayjs(selectedDate).get("month");
	const year = dayjs(selectedDate).get("year");

	const fromHour = dayjs(selectedFromTime).get("hour");
	const fromMinute = dayjs(selectedFromTime).get("minute");
	const fromSecond = dayjs(selectedFromTime).get("second");

	const toHour = dayjs(selectedToTime).get("hour");
	const toMinute = dayjs(selectedToTime).get("minute");
	const toSecond = dayjs(selectedToTime).get("second");

	const fromDateTime = dayjs()
		.set("date", date)
		.set("month", month)
		.set("year", year)
		.set("hour", fromHour)
		.set("minute", fromMinute)
		.set("second", fromSecond)
		.utc();
	const toDateTime = dayjs()
		.set("date", date)
		.set("month", month)
		.set("year", year)
		.set("hour", toHour)
		.set("minute", toMinute)
		.set("second", toSecond)
		.utc();

	return toDateTime.isBefore(fromDateTime) || toDateTime.isSame(fromDateTime);
};

/**
 *
 * @param bytes - Size of file in bytes
 * @returns Size of file in mega bytes
 */
export const bytesToMegaBytes = (bytes: number) => {
	return bytes / (1024 * 1024);
};

export const labelForVisitTypes = (type: "virtual" | "onsite") => {
	if (type === "virtual") {
		return "Virtual";
	} else if (type === "onsite") {
		return "On-Site";
	} else {
		return "";
	}
};

// Returns true if
// given year is valid.
function isLeap(year: number) {
	// Return true if year
	// is a multiple of 4 and
	// not multiple of 100.
	// OR year is multiple of 400.
	return (year % 4 == 0 && year % 100 != 0) || year % 400 == 0;
}

/**
 *
 * @param date - DayJS date which is already formatted to desired timezone
 * @returns If Date is a valid date that exists
 */
export const isDateValid = (date: Dayjs): boolean => {
	// If DayJS does not accept it as a valid date we return false
	if (dayjs(date).isValid() === false) return false;

	const day: number = date.date();
	const month: number = date.month() + 1; //Month is indexed in dayjs meaning january is 0 and december is 11
	const year: number = date.year();
	// If year, month and day
	// are not in given range
	if (year > MAX_VALID_YR || year < MIN_VALID_YR) return false;

	if (month < 1 || month > 12) return false;
	if (day < 1 || day > 31) return false;

	// Handle February month
	// with leap year
	if (month == 2) {
		if (isLeap(year)) return day <= 29;
		else return day <= 28;
	}

	// Months of April, June,
	// Sept and Nov must have
	// number of days less than
	// or equal to 30.
	if (month == 4 || month == 6 || month == 9 || month == 11) return day <= 30;

	return true;
};

export const getRelationshipStatusTextFromValue = (
	status: string | null,
	t: (key: string) => string,
) => {
	if (status === "single") {
		return t("Single");
	} else if (status === "heterosexual") {
		return t("Male / female relationship");
	} else if (status === "samesex") {
		return t("Same-sex relationship");
	}
	return "-";
};

export const getInvitePartnerTextFromValue = (
	status: number,
	t: (key: string) => string,
) => {
	if (status === 1) {
		return t("Yes");
	} else if (status === 0) {
		return t("No");
	} else if (status === 2) {
		return t("Maybe");
	}
	return "-";
};

/**
 *
 * @param password - Password string
 * @returns  If password length is greater than or equal to 8
 */
export const checkMinLength = (password: string) => {
	if (password.length >= 8) {
		return true;
	}
	return false;
};
export const checkLowerCaseCharacter = (password: string) => {
	const regex = /[a-z]/g;
	if (password.match(regex)) {
		return true;
	}
	return false;
};

/**
 *
 * @param password - Password string
 * @returns If password contains a capital letter
 */
export const checkCapitalLetter = (password: string) => {
	const regex = /[A-Z]/g;
	if (password.match(regex)) {
		return true;
	}
	return false;
};

/**
 *
 * @param password - Password string
 * @returns If password contains a number
 */
export const checkNumber = (password: string) => {
	const regex = /[0-9]/g;
	if (password.match(regex)) {
		return true;
	}
	return false;
};

/**
 *
 * @param password - Password string
 * @returns If password contains a special character
 */
export const checkSpecialCharacter = (password: string) => {
	const regex = /[!@#$%^&*(),.?":{}|<>]/g;
	if (password.match(regex)) {
		return true;
	}
	return false;
};

export const getLanguageFromLocale = (
	locale: string,
	t: (key: string) => string,
) => {
	const selectedLang = languageLabels[locale];
	if (selectedLang) {
		return t(selectedLang);
	} else {
		return "";
	}
};

export const checkIfEnteredKeyShouldBeAllowed = (
	e: React.KeyboardEvent<HTMLDivElement>,
	type: "number" | "float",
) => {
	if (type === "number") {
		if (
			Number(e.key) === 0 ||
			Number(e.key) === 1 ||
			Number(e.key) === 2 ||
			Number(e.key) === 3 ||
			Number(e.key) === 4 ||
			Number(e.key) === 5 ||
			Number(e.key) === 6 ||
			Number(e.key) === 7 ||
			Number(e.key) === 8 ||
			Number(e.key) === 9 ||
			e.key === "Backspace" ||
			e.key === " " ||
			e.key === "Delete" ||
			e.key === "ArrowLeft" ||
			e.key === "ArrowRight" ||
			e.key === "+" ||
			e.key === "Tab" ||
			(e.ctrlKey && e.key === "v") ||
			(e.ctrlKey && e.key === "c") ||
			(e.ctrlKey && e.key === "a")
		) {
			return;
		} else {
			e.preventDefault();
		}
	} else if (type === "float") {
		if (
			Number(e.key) === 0 ||
			Number(e.key) === 1 ||
			Number(e.key) === 2 ||
			Number(e.key) === 3 ||
			Number(e.key) === 4 ||
			Number(e.key) === 5 ||
			Number(e.key) === 6 ||
			Number(e.key) === 7 ||
			Number(e.key) === 8 ||
			Number(e.key) === 9 ||
			e.key === "Backspace" ||
			e.key === " " ||
			e.key === "Delete" ||
			e.key === "ArrowLeft" ||
			e.key === "ArrowRight" ||
			e.key === "." ||
			e.key === "Tab" ||
			(e.ctrlKey && e.key === "v") ||
			(e.ctrlKey && e.key === "c")
		) {
			if (e.key === ".") {
				e.preventDefault();
			} else {
				return;
			}
		} else {
			e.preventDefault();
		}
	}
};

/**
 *
 * @param role The current logged in users role
 * @param route The route who's permissions need to be check
 * @param feature The feature of the route who's permission needs to be checked
 * @returns A boolean value based on if the feature is allowed
 */
export const checkPermission = (
	role:
		| "CNP_Administrator"
		| "CNP_CareCoordinator"
		| "CNP_MOA"
		| "CNP_PatientsNavigator"
		| "CNP_Nurse"
		| "CNP_Doctor"
		| "",
	route:
		| "dashboard"
		| "referrals"
		| "leads"
		| "patients"
		| "public-funded-waitlist"
		| "referring-physician"
		| "user-management"
		| "configurations",
	feature: "view" | "add" | "edit" | "delete",
) => {
	if (role) {
		//Getting all the routes this role has
		const roleRoutes = permissions.filter((item) => item.role === role)[0]
			.routes;

		//Getting the route the user is asking for (the route sent in the function as param)
		const allowedRoute = roleRoutes.find((item) => item.route === route);

		//If route is found meaning user has permission for that route then moving forward else sending false
		if (allowedRoute) {
			//Checking is user has the permission for the feature they're asking for (the feature sent in the function as param)
			const isFeatureAllowed = allowedRoute.features.find(
				(item) => item === feature,
			)
				? true
				: false;

			return isFeatureAllowed;
		}
	}

	return false;
};

/**
 *
 * @param role The current logged in users role
 * @returns The default route that the currently loggedIn user needs to be redirected to
 */
export const defaultRedirectingRouteBasedOnRole = (
	role:
		| "CNP_Administrator"
		| "CNP_CareCoordinator"
		| "CNP_MOA"
		| "CNP_PatientsNavigator"
		| "CNP_Nurse"
		| "CNP_Doctor"
		| "",
) => {
	if (role) {
		if (
			role === "CNP_Administrator" ||
			role === "CNP_CareCoordinator" ||
			role === "CNP_Nurse"
		) {
			return "/referrals";
		} else if (role === "CNP_MOA") {
			return "/leads";
		} else if (role === "CNP_PatientsNavigator") {
			return "/patients";
		} else if (role === "CNP_Doctor") {
			return "/leads";
		}
	}
	return "/referrals";
};

export const censorEmailForOTP = (email: string) => {
	const atIndex = email.indexOf("@");

	// If '@' is not found or the email is improperly formatted, return the original email
	if (atIndex === -1 || atIndex < 3) {
		return email;
	}

	// Extract the part of the email before '@'
	const namePart = email.substring(0, atIndex);

	// Determine the number of characters to show
	const charToShow = namePart.length > 3 ? 3 : 2;

	// Censor the email
	const censoredPart = "*".repeat(namePart.length - charToShow);
	const visiblePart = namePart.substring(namePart.length - charToShow);

	return `${censoredPart}${visiblePart}${email.substring(atIndex)}`;
};

interface extractAddressFromPlaceResultProps {
	type: "placeResult";
	addressDetails: google.maps.places.PlaceResult | null;
	countries: Array<{ id: number; name: string }>;
	provinces: CountryProvincesInterface[];
	getProvinceData: (
		countryId: number,
	) => Promise<Array<{ id: number; name: string }>>;
	getCityData: (
		provinceId: number,
	) => Promise<Array<{ id: number; name: string }>>;
	setProvincesState: React.Dispatch<
		SetStateAction<
			Array<{
				id: number;
				name: string;
			}>
		>
	>;
	setCitiesState: React.Dispatch<
		SetStateAction<
			Array<{
				id: number;
				name: string;
			}>
		>
	>;
}

interface extractAddressFromGeocoderResultProps {
	type: "GeocoderResult";
	addressDetails: google.maps.GeocoderResult | null;
	countries: Array<{ id: number; name: string }>;
	provinces: CountryProvincesInterface[];
	getProvinceData: (
		countryId: number,
	) => Promise<Array<{ id: number; name: string }>>;
	getCityData: (
		provinceId: number,
	) => Promise<Array<{ id: number; name: string }>>;
	setProvincesState: React.Dispatch<
		SetStateAction<
			Array<{
				id: number;
				name: string;
			}>
		>
	>;
	setCitiesState: React.Dispatch<
		SetStateAction<
			Array<{
				id: number;
				name: string;
			}>
		>
	>;
}

export const findProvincesByCountryId = (
	provinces: CountryProvincesInterface[],
	countryId: number,
) => {
	if (provinces && provinces.length === 0) return [];
	let left = 0;
	let right = provinces.length - 1;
	let result: CountryProvincesInterface[] = [];
	while (left <= right) {
		const mid = Math.floor((left + right) / 2);
		const province = provinces[mid];

		if (province.conf_country_id === countryId) {
			// Found a province with the desired countryId
			result.push(province);

			// Check left side for more provinces with the same countryId
			let i = mid - 1;
			while (i >= left && provinces[i].conf_country_id === countryId) {
				result.push(provinces[i]);
				i--;
			}

			// Check right side for more provinces with the same countryId
			let j = mid + 1;
			while (j <= right && provinces[j].conf_country_id === countryId) {
				result.push(provinces[j]);
				j++;
			}

			return result;
		} else if (province.conf_country_id < countryId) {
			left = mid + 1;
		} else {
			right = mid - 1;
		}
	}

	// No provinces found with the specified countryId
	return result;
};

type extractAddressProps =
	| extractAddressFromPlaceResultProps
	| extractAddressFromGeocoderResultProps;

/**
 *
 * @param type - The type of location object that will be sent to this function
 * @param addressDetails - The google maps location object. This can either be object that is returned by placeDetails or geocoder
 * @param countries - Array of countries
 * @param getProvinceData - Function to get provinces list
 * @param getCityData - Function to get cities list
 * @param setProvincesState - State where provinces list is stored
 * @param setCitiesState - State where cities list is stored
 * @returns And object with all the relevant information about the location. Returns null if no location was selected
 */
export const extractAddress = async ({
	type,
	addressDetails,
	countries,
	provinces,
	getProvinceData,
	getCityData,
	setProvincesState,
	setCitiesState,
}: extractAddressProps) => {
	if (addressDetails) {
		if (addressDetails.address_components) {
			//Extracting address information
			const latitude: number =
				addressDetails.geometry && addressDetails.geometry.location
					? addressDetails.geometry.location.lat()
					: 0;
			const longitude: number =
				addressDetails.geometry && addressDetails.geometry.location
					? addressDetails.geometry.location.lng()
					: 0;
			const subPremise: google.maps.GeocoderAddressComponent | undefined =
				addressDetails.address_components.find((item) =>
					item.types.includes("subpremise"),
				);
			const streetNumber:
				| google.maps.GeocoderAddressComponent
				| undefined = addressDetails.address_components.find((item) =>
				item.types.includes("street_number"),
			);
			const route: google.maps.GeocoderAddressComponent | undefined =
				addressDetails.address_components.find((item) =>
					item.types.includes("route"),
				);
			const formatted_address: string = addressDetails.formatted_address
				? addressDetails.formatted_address
				: "";
			const postalCode: google.maps.GeocoderAddressComponent | undefined =
				addressDetails.address_components.find((item) =>
					item.types.includes("postal_code"),
				);
			const city: google.maps.GeocoderAddressComponent | undefined =
				addressDetails.address_components.find((item) =>
					item.types.includes("locality"),
				);
			const province: google.maps.GeocoderAddressComponent | undefined =
				addressDetails.address_components.find((item) =>
					item.types.includes("administrative_area_level_1"),
				);
			const region: google.maps.GeocoderAddressComponent | undefined =
				addressDetails.address_components.find((item) =>
					item.types.some((subItem) =>
						subLocalities.includes(subItem),
					),
				);
			const country: google.maps.GeocoderAddressComponent | undefined =
				addressDetails.address_components.find((item) =>
					item.types.includes("country"),
				);
			const placeId: string | undefined = addressDetails.place_id;

			let fetchedProvinces: Array<{ id: number; name: string }> = [];
			let fetchedCities: Array<{ id: number; name: string }> = [];

			//Finding country information based on selected country
			const selectedCountry: { id: number; name: string } | null = country
				? countries.find(
						(item) =>
							item.name.toLowerCase() ===
							country.long_name.toLocaleLowerCase(),
				  ) ?? null
				: null;

			//Getting province data based on selected country
			if (selectedCountry) {
				// fetchedProvinces = await getProvinceData(selectedCountry.id);
				fetchedProvinces = findProvincesByCountryId(
					provinces ? provinces : [],
					selectedCountry.id,
				);
				setProvincesState(fetchedProvinces);
			}

			//Finding province information based on selected province
			const selectedProvince: { id: number; name: string } | null =
				province
					? fetchedProvinces.find(
							(item) =>
								item.name.toLowerCase() ===
								province.long_name.toLocaleLowerCase(),
					  ) ?? null
					: null;

			//Getting city data based on selected province
			if (selectedProvince) {
				fetchedCities = await getCityData(selectedProvince.id);
				setCitiesState(fetchedCities);
			}

			//Finding city information based on selected city
			const selectedCity: { id: number; name: string } | null = city
				? fetchedCities.find(
						(item) =>
							item.name.toLowerCase() ===
							city.long_name.toLocaleLowerCase(),
				  ) ?? null
				: null;

			// returning all relevant values
			return {
				latitude,
				longitude,
				subPremise,
				streetNumber,
				route,
				formatted_address,
				postalCode,
				selectedCity,
				selectedProvince,
				region,
				selectedCountry,
				placeId,
			};
		}
	}
	return null;
};

/**
 *
 * @param role - Role of the user. e.g. "CNP_Administrator"
 * @returns Role text. e.g. "Administrator"
 */
export const formatRoleText = (role: string, t?: (key: string) => string) => {
	// Check if the role has an underscore and a valid second part
	const roleText = role && role.split("_")[1] ? role.split("_")[1] : "";

	if (!roleText) {
		return ""; // Or any default value you want
	}

	// Handle specific cases like "PatientsNavigator"
	if (roleText === "PatientsNavigator") {
		return "Patients Navigator";
	}

	// Handle generic camelCase to spaced text
	const formattedRoleText = roleText.replace(/([a-z])([A-Z])/g, "$1 $2");

	return t ? t(formattedRoleText) : formattedRoleText;
};

export const readFileAsBase64 = (file: any) =>
	new Promise((resolve, reject) => {
		const reader = new FileReader();
		if (reader != null) {
			reader.onload = () => {
				const result = reader.result as string;
				resolve(result.split(",")[1]);
			};
			reader.onerror = reject;
			reader.readAsDataURL(file);
		}
	});

/**
 *
 * @param milliseconds - Time in milliseconds
 * @returns An object with minutes and seconds
 */
export const millisecondsToTime = (milliseconds: number) => {
	const seconds = Math.floor(milliseconds / 1000);
	const minutes = Math.floor(seconds / 60);
	const hours = Math.floor(minutes / 60);

	const minutesValue = minutes - hours * 60;
	const secondsValue = seconds - minutes * 60;
	return {
		minutes: minutesValue > 10 ? minutesValue : `0${minutesValue}`,
		seconds: secondsValue > 10 ? secondsValue : `0${secondsValue}`,
	};
};

/**
 *
 * @param arr1 - First array to compare
 * @param arr2 - Second array to compare
 * @returns If both arrays are same
 */
export const compareArrays = (arr1: Array<number>, arr2: Array<number>) => {
	const sortedArray1 = arr1.sort((a, b) => a - b);
	const sortedArray2 = arr2.sort((a, b) => a - b);

	if (sortedArray1.length !== sortedArray2.length) {
		return false;
	}
	for (let i = 0; i < sortedArray1.length; i++) {
		if (sortedArray1[i] !== sortedArray2[i]) {
			return false;
		}
	}
	return true;
};

/**
 *
 * @param email_or_phone - Value of email or phone number
 * @param type - Type of value. Either "email" or "phone"
 * @param apiFunction - API function to check email or phone validation
 * @returns If email or phone is taken or invalid
 */
export const getEmailOrPhoneValidation = async (
	email_or_phone: string,
	type: "email" | "phone",
	apiFunction: ({
		email_or_phone,
		type,
		patientId,
	}: CheckIfEmailOrPhoneIsTakenProps) => Promise<ResponseType>,
	patientId?: number,
): Promise<{ isTaken: boolean; isInvalid: boolean }> => {
	let isTaken = false;
	let isInvalid = false;
	await apiFunction({
		email_or_phone,
		type,
		patientId,
	}).then((response: any) => {
		if (response.data > 0) {
			isTaken = true;
		} else if (response.data < 0) {
			isInvalid = true;
		}
	});
	return { isTaken, isInvalid };
};

/**
 *
 * @param role - User role
 * @returns If user is a patient or not
 */
export const isPatient = (
	role:
		| "CNP_Administrator"
		| "CNP_CareCoordinator"
		| "CNP_MOA"
		| "CNP_Patients"
		| "CNP_PatientsNavigator"
		| "CNP_Nurse"
		| "CNP_Doctor"
		| "",
) => {
	if (role === "CNP_Patients") {
		return true;
	}
	return false;
};

/**
 * @param isAgreed - Is consent agreed to or not
 * @returns Text to show in the consent
 */

export const optingText = (isAgreed: boolean) => {
	if (isAgreed) {
		return "Opt-out to Opt-in";
	}
	return "Opt-in to Opt-out";
};

/**
 *
 * @param sortingKeyList - Array of objects containing name and key of all the columns in a table that can be sorted
 * @param sortingState - State of the item that needs sorting
 * @returns An object with sortBy and orderBy
 */
export const getSortingInfo = (
	sortingKeyList: Array<{
		name: string;
		key: string;
	}>,
	sortingState: SortingState,
): {
	sortBy: string;
	orderBy: "asc" | "desc";
} => {
	if (sortingState.length) {
		const sortByName = sortingState[0].id;
		const orderBy = sortingState[0].desc ? "desc" : "asc";

		const sortByKey = sortingKeyList.filter(
			(item) => item.name === sortByName,
		)[0].key;

		return {
			sortBy: sortByKey,
			orderBy,
		};
	}
	return {
		sortBy: "",
		orderBy: "asc",
	};
};

export const removeUnwantedCharacters = (value: string): string => {
	const unwantedCharsRegex = /[^\w]/g;

	const cleanedValue = value.replace(unwantedCharsRegex, "");

	return cleanedValue;
};

export const removeUnwantedCharactersAndLetters = (value: string): string => {
	const unwantedCharsRegex = /[^0-9]/g;

	const cleanedValue = value.replace(unwantedCharsRegex, "");

	return cleanedValue;
};

// Disable Emojis in TextField
export const removeEmojisTextFields = (value: string): string => {
	if (value) {
		const regex = value.replace(/[^a-zA-Z0-9 ]/gm, "");
		return regex;
	} else {
		return "";
	}
};

export const getAddress = (address1: string, searchAddress: string) => {
	if (address1) {
		return address1;
	} else if (searchAddress) {
		return searchAddress;
	} else {
		return null;
	}
};

export const getCity = (
	city: { id: number; name: string } | null,
	searchCity: string,
) => {
	if (city !== null) {
		return { address_city_id: city.id, address_city_name: null };
	} else if (searchCity) {
		return {
			address_city_id: null,
			address_city_name: searchCity,
		};
	} else {
		return {
			address_city_id: null,
			address_city_name: null,
		};
	}
};

export const getAddressLatLangAndPlaceId = (data: any) => {
	if (data.address_1) {
		return {
			address_latitude: data.address_latitude
				? data.address_latitude
				: null,
			address_longitude: data.address_longitude
				? data.address_longitude
				: null,
			place_id: data.place_id ? data.place_id : null,
		};
	} else {
		return {
			address_latitude: null,
			address_longitude: null,
			place_id: null,
		};
	}
};

/**
 * Truncates a health card number to a specified length.
 * @param value The health card number string to truncate.
 * @param healthCardNumberSlice The maximum length to truncate the health card number.
 * @param healthCardNumberRegex The maximum length to truncate the health card number.
 * @returns The truncated health card number string.
 */

export const formatHealthCardNumber = (
	value: string,
	healthCardNumberRegex: string,
) => {
	if (!healthCardNumberRegex) return value; // Handle empty regex

	// Remove delimiters (slashes at start and end of regex string)
	const sanitizedRegex = healthCardNumberRegex.replace(/^\/|\/$/g, "");

	// Match `{n}` to calculate the maximum length for truncation
	const sliceLength = Array.from(sanitizedRegex.matchAll(/\{(\d+)\}/g))
		.map((match) => parseInt(match[1], 10))
		.reduce((acc, len) => acc + len, 0);

	// Clean input based on allowed characters in regex
	const allowedCharsRegex = /[A-Z0-9]/g; // Accepts uppercase letters and digits
	const cleanValue = value.match(allowedCharsRegex)?.join("") || "";

	// Truncate to the maximum length based on the regex
	const truncatedValue = cleanValue.slice(0, sliceLength);

	// Dynamically construct the formatting regex
	const regexForFormatting = sanitizedRegex
		.replace(/\[A-Z\]/g, "([A-Z])") // Group alphabet matches
		.replace(/\[0-9\]/g, "([0-9])") // Group numeric matches
		.replace(/\{(\d+)\}/g, (_, len) => `\\d{${len}}`); // Handle numeric length groups

	const healthCardNumberRegExp = new RegExp(regexForFormatting);

	// Count groups to create a dynamic replacement pattern
	const groupCount = (regexForFormatting.match(/\(/g) || []).length;
	const replacement = Array.from(
		{ length: groupCount },
		(_, i) => `$${i + 1}`,
	).join("-");

	// Apply formatting
	const formattedValue = truncatedValue.replace(
		healthCardNumberRegExp,
		replacement,
	);

	// Return formatted value, or fall back to truncated value if no match
	return formattedValue !== truncatedValue ? formattedValue : truncatedValue;
};

export const setTokensAndAuthenticate = (authToken: ecoSystemAuthTokens) => {
	if (authToken) {
		try {
			const { idToken, refreshToken, deviceKey, sub } = authToken;
			const appId = getClinicCredentials("AWS_USERPOOL_APP_ID");

			localStorage.setItem(
				`CognitoIdentityServiceProvider.${appId}.${sub}.idToken`,
				idToken,
			);
			localStorage.setItem(
				`CognitoIdentityServiceProvider.${appId}.${sub}.refreshToken`,
				refreshToken,
			);
			localStorage.setItem(
				`CognitoIdentityServiceProvider.${appId}.LastAuthUser`,
				sub,
			);
			localStorage.setItem(
				`CognitoIdentityServiceProvider.${appId}.${sub}.deviceKey`,
				deviceKey,
			);
			return true;
		} catch (error) {
			console.error("Error parsing authToken:", error);
			return false;
		}
	} else {
		return false;
	}
};

export const filterEnabledProducts = async (
	products: Array<ProductListProps>,
	roles: string[],
) => {
	return products
		.filter((item) => roles.includes(item.shortCode.S))
		.map((item) => {
			return {
				...item,
				redirectionUrl: {
					S: convertRedirectUrl(item.redirectionUrl.S),
				},
			};
		})
		.sort((a, b) => Number(a.sortOrder.S) - Number(b.sortOrder.S));
};

export const convertRedirectUrl = (url: string) => {
	let { href } = window.location;
	let environment = href.split("/")[2].split(".")[0];
	if (href.includes("localhost")) {
		return url.replace("*", "staging");
	} else {
		return url.replace("*", environment);
	}
};

type StateType = LeadDetailsInterface | PatientDetailInterface;

export const handleAppointmentEvent = <T extends StateType>(
	data: {
		appointment: AppointmentInterface;
		event_type: string;
	},
	setState: React.Dispatch<React.SetStateAction<T>>,
) => {
	const updateAppointments = (appointments: AppointmentInterface[]) => {
		return appointments
			.filter((item) => item.id !== data.appointment.id)
			.concat(data.appointment)
			.sort((a, b) => (dayjs(b.from).isAfter(dayjs(a.from)) ? 1 : -1));
	};
	if (data.event_type === "cancelled" || data.event_type === "updated") {
		setState((prev) => {
			return {
				...prev,
				appointments: updateAppointments(prev.appointments),
			};
		});
	}

	if (data.event_type === "created") {
		setState((prev) => {
			return {
				...prev,
				appointments: updateAppointments(prev.appointments),
			};
		});
	}
};

/**
 * Checks if certain conditions are met based on the provided configuration and priority.
 * @param configBoolean An object containing boolean configuration flags.
 * @param priority The priority string to check against.
 * @returns A boolean indicating if the conditions are met.
 */
export const checkPriorityTypeConditions = (
	configBoolean: { [key: string]: boolean } | null,
	priorityType: string | null | undefined,
): boolean => {
	if (configBoolean && configBoolean.isSendInviteHideOnPriority === false) {
		return true;
	} else if (priorityType !== "Gynaecology") {
		return true;
	}
	return false;
};

// Function that return string if array for search query

export const queryArrayToString = (
	queryArray: string[] | string | undefined,
) => {
	if (Array.isArray(queryArray)) {
		return queryArray.join(", ");
	} else {
		return queryArray;
	}
};

/**
 * Validates and formats a health card number using a provided regex.
 * @param healthCard - The health card number to validate and format.
 * @param regex - The regex pattern to validate against.
 * @param formatter - Optional. A function to format the health card number after validation.
 * @returns The formatted health card number if valid, otherwise null.
 */
export const validateAndFormatHealthCard = (
	healthCard: string,
	regex: RegExp,
	formatter?: (input: string) => string,
): string | null => {
	// Validate the input against the regex
	if (!regex.test(healthCard)) {
		console.error("Invalid health card format.");
		return null;
	}

	// Format the input if a formatter is provided
	return formatter ? formatter(healthCard) : healthCard;
};
