import Dexie, { Table } from "dexie";
import * as DbTypes from "./dbTypes";

export class MainDB extends Dexie {
	archivedReasons!: Table<DbTypes.ArchivedReasonsInterface>;
	doctorList!: Table<DbTypes.DoctorListInterface>;
	globalConfig!: Table<DbTypes.GlobalConfigInterface>;
	careCoordinator!: Table<DbTypes.CareCoordinatorInterface>;
	priorityList!: Table<DbTypes.PriorityListInterface>;
	clinicLanguages!: Table<DbTypes.ClinicLanguagesInterface>;
	contactResolution!: Table<DbTypes.ContactResolutionInterface>;
	virtualCareProvider!: Table<DbTypes.VirtualCareProviderInterface>;
	appointmentCancelledReasons!: Table<DbTypes.AppointmentCancelledReasonsInterface>;
	roles!: Table<DbTypes.RolesInterface>;
	referringPhysicianOptoutDropdown!: Table<DbTypes.ReferringPhysicianOptoutDropdownInterface>;
	specialtyDropdown!: Table<DbTypes.SpecialtyDropDownInterface>;
	locations!: Table<DbTypes.LocationsInterface>;
	initialContactChannel!: Table<DbTypes.InitialContactChannelInterface>;
	benefitProvider!: Table<DbTypes.BenefitProviderInterface>;
	patientPreferences!: Table<DbTypes.PatientPreferencesInterface>;
	reasonForReferrals!: Table<DbTypes.ReasonForReferralsInterface>;
	patientLanguages!: Table<DbTypes.PatientLanguagesInterface>;
	referringPhysicians!: Table<DbTypes.ReferringPhysiciansInterface>;
	country!: Table<DbTypes.CountriesInterface>;
	pronouns!: Table<DbTypes.PronounsInterface>;
	appointmentTypes!: Table<DbTypes.AppointmentTypesInterface>;
	faxReferralArchiveReasons!: Table<DbTypes.FaxReferralArchiveReasonsInterface>;
	visitingPurpose!: Table<DbTypes.VisitingPurposeInterface>;
	countryProvinces!: Table<DbTypes.CountryProvincesInterface>;
	preferredMethodOfContact!: Table<DbTypes.PreferredMethodOfContactInterface>;
	healthCardProvinceInformation!: Table<DbTypes.HealthCardProvinceInformationInterface>;
	constructor() {
		super("CP_Database");
		this.version(14).stores({
			// update the version every time new data model is added
			archivedReasons: Object.keys(
				new DbTypes.ArchivedReasonsClass(),
			).join(", "),

			doctorList: Object.keys(new DbTypes.DoctorListClass()).join(", "),

			globalConfig: Object.keys(new DbTypes.GlobalConfigClass()).join(
				", ",
			),

			careCoordinator: Object.keys(
				new DbTypes.CareCoordinatorClass(),
			).join(", "),

			priorityList: Object.keys(new DbTypes.PriorityListClass()).join(
				", ",
			),

			clinicLanguages: Object.keys(
				new DbTypes.ClinicLanguagesClass(),
			).join(", "),

			contactResolution: Object.keys(
				new DbTypes.ContactResolutionClass(),
			).join(", "),

			virtualCareProvider: Object.keys(
				new DbTypes.VirtualCareProviderClass(),
			).join(", "),

			appointmentCancelledReasons: Object.keys(
				new DbTypes.AppointmentCancelledReasonsClass(),
			).join(", "),

			roles: Object.keys(new DbTypes.RolesClass()).join(", "),

			referringPhysicianOptoutDropdown: Object.keys(
				new DbTypes.ReferringPhysicianOptoutDropdownClass(),
			).join(", "),

			specialtyDropdown: Object.keys(
				new DbTypes.SpecialtyDropDownClass(),
			).join(", "),

			locations: Object.keys(new DbTypes.LocationsClass()).join(", "),

			initialContactChannel: Object.keys(
				new DbTypes.InitialContactChannelClass(),
			).join(", "),

			benefitProvider: Object.keys(
				new DbTypes.BenefitProviderClass(),
			).join(", "),

			patientPreferences: Object.keys(
				new DbTypes.PatientPreferencesClass(),
			).join(", "),

			reasonForReferrals: Object.keys(
				new DbTypes.ReasonForReferralsClass(),
			).join(", "),

			patientLanguages: Object.keys(
				new DbTypes.PatientLanguagesClass(),
			).join(", "),

			referringPhysicians: Object.keys(
				new DbTypes.ReferringPhysiciansClass(),
			).join(", "),

			country: Object.keys(new DbTypes.CountriesClass()).join(", "),

			pronouns: Object.keys(new DbTypes.PronounsClass()).join(", "),

			appointmentTypes: Object.keys(
				new DbTypes.AppointmentTypesClass(),
			).join(", "),

			faxReferralArchiveReasons: Object.keys(
				new DbTypes.FaxReferralArchiveReasonsClass(),
			).join(", "),

			visitingPurpose: Object.keys(
				new DbTypes.VisitingPurposeClass(),
			).join(", "),

			countryProvinces: Object.keys(
				new DbTypes.CountryProvincesClass(),
			).join(", "),

			preferredMethodOfContact: Object.keys(
				new DbTypes.PreferredMethodOfContactClass(),
			).join(", "),
			healthCardProvinceInformation: Object.keys(
				new DbTypes.HealthCardProvinceInformationClass(),
			).join(", "),
		});
	}
}

export const mainDb = new MainDB();
