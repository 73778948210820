import React, { useEffect, useState } from "react";
import InactivityModal from "../InactivityModal";
import useInactivityTimer from "../../hooks/useInactivityTimer";
import { logout } from "@/helpers/auth";
import { useSelector } from "react-redux";
import { RootState } from "@/redux/store";

function InActivityWrapper() {
	const { isAuthenticated } = useSelector((state: RootState) => state.auth);

	const [openInactivityModal, setOpenInactivityModal] = useState(false);

	// The inactivity timer is synced between tabs using local storage but the state that manages the modal open state is managed individually in each tab. We create a separate value in local storage which we listen to and update it whenever user clicks the close button on the modal. When the value is updated, we close the modal in all tabs.
	const handleStorageListener = (event: StorageEvent) => {
		if (event.key === "closeInactivityModal") {
			if (event.newValue === "true") {
				setOpenInactivityModal(false);
				localStorage.setItem("closeInactivityModal", "false");
			}
		}
	};

	useEffect(() => {
		window.addEventListener("storage", handleStorageListener);

		return () => {
			window.removeEventListener("storage", handleStorageListener);
		};
	}, []);

	const onInactivityModalClose = () => {
		setOpenInactivityModal(false);
		localStorage.setItem("closeInactivityModal", "true");
	};

	// Handling user inactivity
	const onIdle = async () => {
		if (isAuthenticated) {
			onInactivityModalClose();
			await logout();
		}
	};

	const onPrompt = () => {
		if (isAuthenticated) {
			setOpenInactivityModal(true);
		}
	};

	const { getTimeBeforeIdle, resetTimeout } = useInactivityTimer({
		onIdle,
		onPrompt,
		timeout: 1000 * 60 * 490, // 15 minutes
		promptBeforeIdle: 1000 * 60 * 480, // 10 minutes
		// timeout: 1000 * 60 * 2, // 2 minutes (used for testing)
		// promptBeforeIdle: 1000 * 60, // 1 minute (used for testing)
		disableHandleUserActivity: openInactivityModal,
	});

	return (
		<>
			<InactivityModal
				open={openInactivityModal}
				onClose={onInactivityModalClose}
				getRemainingTime={getTimeBeforeIdle}
				activate={resetTimeout}
			/>
		</>
	);
}

export default React.memo(InActivityWrapper);
