import { Wrapper } from "@googlemaps/react-wrapper";
import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import { ThemeProvider } from "@mui/material/styles";
import * as Sentry from "@sentry/nextjs";
import { Amplify, Auth } from "aws-amplify";
import type { AppProps } from "next/app";
import dynamic from "next/dynamic";
import Head from "next/head";
import { useEffect } from "react";
import TagManager from "react-gtm-module";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import "../helpers/i18n";
import {
	apiName,
	getClinicCredentials,
	getClinicDomain,
} from "../helpers/utils";
import "../styles/globals.css";
import { MuiTheme } from "../styles/theme";
import { persistor, store } from "../redux/store";
import { ComponentWrapper } from "../components";

const ErrorBoundary = dynamic(() => import("../components/ErrorBoundary"), {
	ssr: false,
});

const App = ({ Component, pageProps }: AppProps) => {
	useEffect(() => {
		//Configuring amplify
		Amplify.configure({
			Auth: {
				region: getClinicCredentials("AWS_REGION"),
				userPoolId: getClinicCredentials("AWS_USERPOOL_ID"),
				userPoolWebClientId: getClinicCredentials(
					"AWS_USERPOOL_APP_ID",
				),
				identityPoolId: getClinicCredentials("AWS_IDENTITYPOOL_ID"),
				authenticationFlowType:
					getClinicCredentials("AWS_AUTHFLOW_TYPE"),
				mandatorySignIn: true,
			},
			API: {
				endpoints: [
					{
						name: apiName,
						endpoint: getClinicCredentials("BACKEND_URL"),
						region: getClinicCredentials("AWS_REGION"),
						custom_header: async () => {
							return {
								idToken: `${(await Auth.currentSession())
									.getIdToken()
									.getJwtToken()}`,
							};
						},
					},
				],
			},
			Storage: {
				AWSS3: {
					bucket: getClinicCredentials("AWS_S3_BUCKET"),
					region: getClinicCredentials("AWS_REGION"),
				},
			},
		});
		// added tags for each clinic in sentry
		Sentry.setTag("clinic_name", getClinicDomain());
	}, []);

	const tagManagerArgs = {
		gtmId: getClinicCredentials("GOOGLE_TAG_MANAGER_ID") || "",
	};

	TagManager.initialize(tagManagerArgs);

	return (
		<>
			<Head>
				<meta
					name="viewport"
					content="width=1440, viewport-fit=cover"
				/>
				<meta name="description" content="Clinic Portal CNP" />
				<link
					rel="preconnect"
					href={`${getClinicCredentials("BACKEND_URL")}`}
				/>
				<link
					rel="icon"
					href={`${getClinicCredentials(
						"STATIC_CONTENT_BUCKET",
					)}/${getClinicDomain().toUpperCase()}/frontend_images/favicon.ico`}
				/>
			</Head>
			<Provider store={store}>
				<PersistGate loading={null} persistor={persistor}>
					<ThemeProvider theme={MuiTheme}>
						<StyledEngineProvider injectFirst>
							<ErrorBoundary>
								<Wrapper
									apiKey={getClinicCredentials(
										"GOOGLE_API_KEY",
									)}
									libraries={["places"]}
								>
									<ComponentWrapper
										Component={Component}
										pageProps={pageProps}
									/>
								</Wrapper>
							</ErrorBoundary>
						</StyledEngineProvider>
					</ThemeProvider>
				</PersistGate>
			</Provider>
		</>
	);
};

export default dynamic(() => Promise.resolve(App), {
	ssr: false,
});
