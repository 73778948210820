import { useCallback, useEffect, useRef } from "react";
import * as worker from "worker-timers";

interface UseInactivityTimerProps {
	onIdle: () => void; // callback when user is idle
	onAction?: () => void; // callback when user is active
	onPrompt?: () => void; // callback when user is about to be idle
	timeout?: number; // time after which user is considered idle in milliseconds
	promptBeforeIdle?: number; // time at which prompt needs to be shown in milliseconds
	disableHandleUserActivity?: boolean; // disables handleUserActivity when set to true. (We use this in cases when we do not want the actions the user performs to count as activity. In our use case this is then the modal is open)
}

export default function useInactivityTimer({
	onIdle,
	onAction,
	onPrompt,
	timeout = 1000 * 60 * 15,
	promptBeforeIdle = 1000 * 60 * 10,
	disableHandleUserActivity = false,
}: UseInactivityTimerProps) {
	// refs to store different time states

	const timeWhenOnIdleTimeoutStartedRef = useRef<number>(0);
	const timeWhenUserWasLastActiveRef = useRef<number>(0);

	// refs for timeout ids
	const promptTimeoutIdRef = useRef<number | null>(null);
	const timeoutIdRef = useRef<number | null>(null);

	// Emitters which run whenever the functions we get from props get updated. The emitter emits the updated function to our function refs
	// On Idle Emitter
	const emitOnIdle = useRef(onIdle);
	useEffect(() => {
		emitOnIdle.current = onIdle;
	}, [onIdle]);

	// On Prompt Emitter
	const emitOnAction = useRef(onAction);
	useEffect(() => {
		emitOnAction.current = onAction;
	}, [onAction]);

	// On Prompt Emitter
	const emitOnPrompt = useRef(onPrompt);
	useEffect(() => {
		emitOnPrompt.current = onPrompt;
	}, [onPrompt]);

	//Function that clears any previously set the timers
	const clearTimers = () => {
		if (timeoutIdRef.current) {
			worker.clearTimeout(timeoutIdRef.current);
			timeoutIdRef.current = null;
		}

		if (promptTimeoutIdRef.current) {
			worker.clearTimeout(promptTimeoutIdRef.current);
			promptTimeoutIdRef.current = null;
		}
	};

	// Function that resets all the timers
	const resetTimeout = useCallback(() => {
		clearTimers();

		timeWhenOnIdleTimeoutStartedRef.current = Date.now();

		timeoutIdRef.current = worker.setTimeout(() => {
			emitOnIdle.current();
		}, timeout);

		promptTimeoutIdRef.current = worker.setTimeout(() => {
			emitOnPrompt.current && emitOnPrompt.current();
		}, promptBeforeIdle);
	}, [timeout, promptBeforeIdle]);

	// Function that syncs the activity across tabs. It checks if the last activity performed time in our state is greater than the last activity performed time in local storage. If it is then we update the state and reset the timer
	const syncActivityAcrossTabs = useCallback(() => {
		const lastActivity = Number(localStorage.getItem("lastActivity"));
		if (lastActivity > timeWhenUserWasLastActiveRef.current) {
			timeWhenUserWasLastActiveRef.current = lastActivity;
			resetTimeout();
		}
		// Disabling exhaustive deps because we want to update this function whenever the timeWhenUserWasLastActiveRef.current changes
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [timeWhenUserWasLastActiveRef.current, resetTimeout]);

	// Function that updates the last activity performed time in local storage and in our state
	const updateActivity = useCallback(() => {
		const now = Date.now();
		localStorage.setItem("lastActivity", now.toString());
		timeWhenUserWasLastActiveRef.current = now;
	}, []);

	// Function that runs whenever user performs any activity
	const handleUserActivity = useCallback(() => {
		emitOnAction.current && emitOnAction.current();
		updateActivity();
		resetTimeout();
	}, [emitOnAction, updateActivity, resetTimeout]);

	// useEffect that adds event listeners for different user activities
	useEffect(() => {
		if (disableHandleUserActivity === false) {
			window.addEventListener("mousemove", handleUserActivity);
			window.addEventListener("mousedown", handleUserActivity);
			window.addEventListener("keypress", handleUserActivity);
			window.addEventListener("touchmove", handleUserActivity);
			window.addEventListener("scroll", handleUserActivity);

			resetTimeout();
		} else {
			window.removeEventListener("mousemove", handleUserActivity);
			window.removeEventListener("mousedown", handleUserActivity);
			window.removeEventListener("keypress", handleUserActivity);
			window.removeEventListener("touchmove", handleUserActivity);
			window.removeEventListener("scroll", handleUserActivity);
		}
	}, [disableHandleUserActivity, handleUserActivity, resetTimeout]);

	// useEffect that adds event listener for storage event. This event is emitted whenever the local storage is updated in any tab. So we use this event to sync the activity across tabs
	useEffect(() => {
		window.addEventListener("storage", syncActivityAcrossTabs);

		return () => {
			window.removeEventListener("storage", syncActivityAcrossTabs);
		};
	}, [syncActivityAcrossTabs]);

	// useEffect that runs whenever the disableHandleUserActivity prop becomes false (Meaning the modal is closed in our useCase) It resets the timer and updates the activity in local storage
	useEffect(() => {
		if (disableHandleUserActivity === false) {
			updateActivity();
			resetTimeout();
		}
	}, [disableHandleUserActivity, updateActivity, resetTimeout]);

	// Function that returns the time left before user is considered idle
	const getTimeBeforeIdle = () =>
		timeout - (Date.now() - timeWhenOnIdleTimeoutStartedRef.current);

	return {
		getTimeBeforeIdle,
		resetTimeout,
	};
}
