import { Box, Button, Stack, Typography, alpha, useTheme } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { useTranslation } from "react-i18next";
import Icon from "../components/icons/icon";
import { millisecondsToTime } from "../helpers/utils";
import { useEffect, useState } from "react";
import { logout } from "@/helpers/auth";

interface InactivityModalProps {
	open: boolean;
	onClose: () => void;
	getRemainingTime: () => number;
	activate: () => void;
}

export default function InactivityModal({
	open,
	onClose,
	getRemainingTime,
	activate,
}: InactivityModalProps) {
	// Theme selector
	const theme = useTheme();

	const { t } = useTranslation();

	const [remainingTime, setRemainingTime] = useState(getRemainingTime());

	useEffect(() => {
		const intervalId = setInterval(() => {
			setRemainingTime(getRemainingTime());
		}, 1000);

		return () => clearInterval(intervalId);
	}, [getRemainingTime]);

	const handleClose = (reason: string | undefined) => {
		if (reason && reason === "backdropClick") return;
		onClose();
	};

	const handleSignOut = async () => {
		await logout();
		handleClose(undefined);
	};

	const handleActivate = () => {
		activate();
		handleClose(undefined);
	};

	return (
		<Dialog
			onClose={(
				event: React.SyntheticEvent | MouseEvent,
				reason: string | undefined,
			) => {
				handleClose(reason);
			}}
			aria-labelledby="customized-dialog-title"
			open={open}
			sx={{
				p: 5,
				"& .MuiDialog-paper": {
					maxWidth: "473px",
					width: 1,
					position: "relative",
				},
			}}
		>
			<Box className="dialogElement">
				<span></span>
				<svg
					className="blobSvg"
					version="1.1"
					xmlns="http://www.w3.org/2000/svg"
					viewBox="0 0 500 500"
					width="100%"
					id="blobSvg"
				>
					<path id="blob" fill={theme.palette.secondary.main}>
						<animate
							attributeName="d"
							dur="5000ms"
							repeatCount="indefinite"
							values="M440.5,320.5Q418,391,355.5,442.5Q293,494,226,450.5Q159,407,99,367Q39,327,31.5,247.5Q24,168,89,125.5Q154,83,219.5,68Q285,53,335.5,94.5Q386,136,424.5,193Q463,250,440.5,320.5Z;M453.78747,319.98894Q416.97789,389.97789,353.96683,436.87838Q290.95577,483.77887,223.95577,447.43366Q156.95577,411.08845,105.64373,365.97789Q54.33169,320.86732,62.67444,252.61056Q71.01719,184.3538,113.01965,135.21007Q155.02211,86.06634,220.52211,66.46683Q286.02211,46.86732,335.5,91.94472Q384.97789,137.02211,437.78747,193.51106Q490.59704,250,453.78747,319.98894Z;M411.39826,313.90633Q402.59677,377.81265,342.92059,407.63957Q283.24442,437.46649,215.13648,432.5428Q147.02853,427.61911,82.23325,380.9572Q17.43796,334.29529,20.45223,250.83809Q23.46649,167.38089,82.5856,115.05707Q141.70471,62.73325,212.19045,63.73015Q282.67618,64.72705,352.67308,84.79839Q422.66998,104.86972,421.43486,177.43486Q420.19974,250,411.39826,313.90633Z;M440.5,320.5Q418,391,355.5,442.5Q293,494,226,450.5Q159,407,99,367Q39,327,31.5,247.5Q24,168,89,125.5Q154,83,219.5,68Q285,53,335.5,94.5Q386,136,424.5,193Q463,250,440.5,320.5Z;"
						></animate>
					</path>
				</svg>
			</Box>
			<DialogContent
				dividers
				sx={{
					zIndex: 1,
				}}
			>
				<Typography variant="h6" mb={1}>
					{t("Are you still there")}?
				</Typography>
				<Typography
					variant="body2"
					mb={3}
					sx={{
						color: alpha(theme.palette.primary.main, 0.8),
					}}
				>
					{t(
						"To protect your information, we will automatically log you out after a period of no activity",
					)}
					.
				</Typography>
				<Box className="flex gap-10" mb={3}>
					<Icon icon="time" />
					<Box>
						<Typography
							variant="body2"
							className="fontWeight-medium"
							sx={{
								color: alpha(theme.palette.primary.main, 0.8),
							}}
						>
							{t("Remaning time")}
						</Typography>
						<Typography
							variant="body2"
							className="fontWeight-semibold"
							sx={{
								color: alpha(theme.palette.primary.main, 0.8),
							}}
						>
							{`${millisecondsToTime(remainingTime).minutes}:${
								millisecondsToTime(remainingTime).seconds
							}`}
						</Typography>
					</Box>
				</Box>
			</DialogContent>
			<DialogActions>
				<Stack direction="row" spacing={3} className="w-100">
					<Button
						id="sign-out-button"
						onClick={handleSignOut}
						variant="outlined"
						className="w-100"
						sx={{
							padding: "13px 0",
						}}
						aria-label="Sign Out"
					>
						{t("Sign Out")}
					</Button>
					<Button
						id="yes-im-here"
						onClick={handleActivate}
						variant="secondary"
						className="w-100"
						sx={{
							padding: "13px 0",
						}}
						aria-label="Yes, I'm Here"
					>
						{t("Yes, I’m here")}
					</Button>
				</Stack>
			</DialogActions>
		</Dialog>
	);
}
